export default class ColorUtils {

	public static webbleUpGreen: string = '#48c16f'

	/**
	 * Get the contrasting color of a hexadecimal color
	 * @param hexColor hexadecimal color
	 * @param fallbackColor Color to pick 
	 * @returns color in hexadecimal form
	 */
	static contrastColor(hexColor: string, fallbackColor: string = '#000000'): string {
		try {
			const rgb: any = this.hexToRgb(hexColor);
			if (!rgb) {
				return fallbackColor;
			}

			const luminance = (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;
			if (luminance > 0.7) {
				return "#000000"
			} else {
				return "#ffffff"
			}
		} catch (e) {
			console.error(e);
			return fallbackColor;
		}
	}
	
	/**
	 * Convert color in hexadecimal form to rgb form
	 * @param hex string in hexadecimal form
	 * @returns rgb object or null
	 */
	static hexToRgb(hex: string): any {
		const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
		hex = hex.replace(shorthandRegex, function (m, r, g, b) {
			return r + r + g + g + b + b;
		});
		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result ? {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16)
		} : null;
	}
}