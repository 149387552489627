import Question from './Question'

export default class Poll {
	private id: number | null;
	private title: string | null;
	private description: string | null;
	private questions: Array<Question> | null;
	private active: boolean;
	private language: string | null;
	private type: string | null;
	private show_live_results: boolean;
	private see_own_answers: boolean;

	constructor(
		language: string = '',
		id: number | null = null,
		title: string | null = null,
		description: string | null = null,
		active: boolean = false,
		type: string | null = null,
		show_live_results: boolean = false,
		see_own_answers: boolean = false,
	) {
		this.id = id;
		this.title = title;
		this.description = description;
		this.active = active;
		this.language = language;
		this.type = type;
		this.show_live_results = show_live_results;
		this.see_own_answers = see_own_answers;
	}

	public static fromJSON(json: any): Poll {
		let poll = new Poll();
		Object.assign(poll, json);

		//check if poll has content otherwise use default content
		if (json.content?.length > 0) {
			poll.$title = json.content[0].title;
			poll.$description = json.content[0].description;
			poll.$language = json.content[0].language;
		} else if (json.default_content?.length > 0) {
			poll.$title = json.default_content[0].title;
			poll.$description = json.default_content[0].description;
			poll.$language = json.default_content[0].language;
		}
		else {
			poll.$title = "No title";
			poll.$description = "No description";
			poll.$language = "EN";
		}

		poll.$questions = json.questions?.map((question) => Question.fromJSON(question));

		return poll;
	}

	public get $id(): number | null {
		return this.id;
	}

	public get $active(): boolean | null {
		return this.active;
	}

	public get $language(): string | null {
		return this.language;
	}

	public get $title(): string | null {
		return this.title;
	}

	public get $description(): string | null {
		return this.description;
	}

	public get $type(): string | null {
		return this.type;
	}

	public get $show_live_results(): boolean {
		return this.show_live_results;
	}

	public get $see_own_answers(): boolean {
		return this.see_own_answers;
	}

	public get $questions(): Array<Question> | null {
		return this.questions;
	}

	public set $questions(value: Array<Question> | null) {
		this.questions = value;
	}

	public set $id(value: number | null) {
		this.id = value;
	}

	public set $title(value: string | null) {
		this.title = value;
	}

	public set $description(value: string | null) {
		this.description = value;
	}

	public set $active(value: boolean) {
		this.active = value;
	}

	public set $language(value: string | null) {
		this.language = value;
	}

	public set $type(value: string | null) {
		this.type = value;
	}

	public set $show_live_results(value: boolean) {
		this.show_live_results = value;
	}

	public set $see_own_answers(value: boolean) {
		this.see_own_answers = value;
	}
}