import Option from './Option';

export default class Question {
	private id: number | null;
	private title: string | null;
	private description: string | null;
	private type: string | null;
	private options: Array<Option> | null;
	private active: boolean | null;
	private language: string | null;
	private graph_type: string | null;
	private timed: boolean | null;

	constructor(
		language: string | null = null,
		id: number | null = null,
		title: string | null = null,
		description: string | null = null,
		type: string | null = null,
		options: Array<Option> | null = null,
		active: boolean | null = null,
		graph_type: string | null = null,
		timed: boolean | null = null,
	) {
		this.id = id;
		this.title = title;
		this.description = description;
		this.type = type ?? 'CHECKBOX';
		this.options = options ?? [];
		this.active = active ?? true;
		this.language = language;
		this.graph_type = graph_type ?? 'BAR';
		this.timed = timed ?? false;
	}

	public static fromJSON(json: any): Question {
		let question = new Question();
		Object.assign(question, json);

		//check if poll has content otherwise use default content
		if (json.content.length > 0) {
			question.$title = json.content[0].title;
			question.$description = json.content[0].description;
			question.$language = json.content[0].language;
		}
		else if (json.default_content.length > 0) {
			question.$title = json.default_content[0].title;
			question.$description = json.default_content[0].description;
			question.$language = json.default_content[0].language;
		}
		else {
			question.$title = "No title";
			question.$description = "No description";
			question.$language = "EN";
		}

		question.$options = json.options?.map((option) => Option.fromJSON(option));

		return question;
	}


	public get $id(): number | null {
		return this.id;
	}

	public get $title(): string | null {
		return this.title;
	}

	public get $description(): string | null {
		return this.description;
	}

	public get $type(): string | null {
		return this.type;
	}

	public get $options(): Array<Option> | null {
		return this.options;
	}

	public get $active(): boolean | null {
		return this.active;
	}

	public get $language(): string | null {
		return this.language;
	}

	public get $graph_type(): string | null {
		return this.graph_type;
	}

	public get $timed(): boolean | null {
		return this.timed;
	}

	public set $graph_type(value: string | null) {
		this.graph_type = value;
	}

	public set $language(value: string | null) {
		this.language = value;
	}

	public set $active(value: boolean | null) {
		this.active = value;
	}


	public set $id(value: number | null) {
		this.id = value;
	}

	public set $title(value: string | null) {
		this.title = value;
	}

	public set $description(value: string | null) {
		this.description = value;
	}

	public set $type(value: string | null) {
		this.type = value;
	}

	public set $options(value: Array<Option> | null) {
		this.options = value;
	}

	public set $timed(value: boolean | null) {
		this.timed = value;
	}

	public static getTypes(): Array<string> {
		return [
			'CHECKBOX',
			'RADIO',
			'RATING'
		];
	}

	public static getGraphTypes(): Array<string> {
		return [
			'BAR',
			'PIE',
			'TEXT',
		];
	}


	public validate(): { [key: string]: any } | null {
		const errors: { [key: string]: any } = {};

		// Check if the required fields are not null
		if (this.title === null) {
			errors.title = "Title is required";
		}

		if (this.type === null) {
			errors.type = "Type is required";
		}

		// Check if the 'type' property is one of the allowed values
		if (this.type && !Question.getTypes().includes(this.type)) {
			errors.type = "Invalid type";
		}

		// Check if the 'options' property is not null
		if (this.options === null) {
			errors.options = "Options are required";
		}

		// Validate the options
		if (this.options) {
			this.options.forEach((option) => {
				const optionErrors = option.validate();
				if (optionErrors) {
					errors.options = optionErrors;
				}
			})
		}

		// Additional validation logic can be added here if needed

		// If there are errors, return the error object; otherwise, return null
		return Object.keys(errors).length > 0 ? errors : null;
	}

}