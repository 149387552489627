import AuthService from './AuthService';
import Poll from '../Models/Poll';
import Question from '../Models/Question';
import axios from 'axios';

const QuestionService = {
	store: async function (eventId, pollId, question: Question): Promise<Question> {
		const response = await AuthService.post(`event/${eventId}/polls/${pollId}/questions`, question);
		return Question.fromJSON(response.data.data);
	},
	get: async function (eventId, pollId, questionId): Promise<Question> {
		const response = await AuthService.get(`event/${eventId}/polls/${pollId}/questions/${questionId}`);
		return Question.fromJSON(response.data.data);
	},
	getAll: async function (eventId, pollId): Promise<Question[]> {
		const response = await AuthService.get(`event/${eventId}/polls/${pollId}/questions`);
		let questions: Array<Question> = [];
		for (var i = 0; i < response.data.data.questions.length; i++) {
			questions.push(Question.fromJSON(response.data.data.questions[i]));
		}
		return questions;
	},
	update: async function (eventId, pollId, question: Question): Promise<Poll> {
		const response = await AuthService.put(`event/${eventId}/polls/${pollId}/questions/${question.$id}`, question);
		return Poll.fromJSON(response.data.data);
	},
	delete: async function (eventId, pollId, questionId): Promise<any> {
		return await AuthService.delete(`event/${eventId}/polls/${pollId}/questions/${questionId}`);
	},
	activate: async function (eventId, pollId, questionId, isActive): Promise<any> {
		return await AuthService.put(`event/${eventId}/polls/${pollId}/questions/${questionId}/activate`, { is_active: isActive });
	},
	getAnswers: async function (eventId, pollId, questionId): Promise<any> {
		const response = await AuthService.get(`event/${eventId}/polls/${pollId}/questions/${questionId}/answers`);
		return response.data.data;
	},
	getAnswersGraph: async function (eventId, pollId, questionId): Promise<any> {
		const response = await axios.get(process.env.REACT_APP_BASE_URL + `event/${eventId}/polls/${pollId}/questions/${questionId}/answers/graph`);
		return response.data.data;
	},
}

export default QuestionService;