import axios from 'axios';
import Event from '../Models/Event';
import Block from '../Models/Block';
import Service from '../Models/Service';
import User from '../Models/User';

import AuthService from './AuthService';

const EventService = {
	getPage: async function (per_page = 10, page = 1, search = "", mode = "all", sort = 'asc', organisation_id = null): Promise<any> {
		const params = {
			per_page: per_page,
			page: page,
			search: search,
			mode: mode,
			sort: sort,
			organisation_id: organisation_id,
		};

		const response = await AuthService.get('event/all', params);
		var events: Array<Event> = [];
		const data = response.data.data;
		const eventData = data.data;

		for (var i = 0; i < eventData.length; i++) {
			const event = Event.fromJSON(eventData[i]);
			events.push(event);
		}
		data.data = events;
		return data;
	},
	/**
	 * Get all the events, including event data
	 *
	 * @return The response from the server, that includes the events
	 */
	getAll: async function (): Promise<Array<Event>> {
		const response = await AuthService.get('event/all');
		var events: Array<Event> = [];
		const eventResponseData = response.data.data.data;

		for (var i: number = 0; i < eventResponseData.length; i++) {
			const user: Event = Event.fromJSON(eventResponseData[i]);
			events.push(user);
		}

		return events;
	},
	/**
	 * Get all event with id
	 *
	 * @return The response from the server, that the selected event
	 */
	get: async function (id: number, languageSlug: String = undefined, eventState: number = undefined, withZones: boolean = false, zoneId = undefined): Promise<Event> {
		let params = {};
		if (languageSlug) {
			params['languageSlug'] = languageSlug
		}
		if (eventState) {
			params['eventState'] = eventState;
		}

		if (zoneId) {
			params['zone'] = zoneId;
		}

		if (withZones) {
			params['zones'] = 1;
		}

		const response = await AuthService.get('event/' + id, params);
		const eventResponseData = response.data.data;
		const event: Event = Event.fromJSON(eventResponseData);
		return event;
	},

	getAllTemplates: async function (): Promise<JSON> {
		const response = await AuthService.get('/eventTemplate');
		return response;
	},

	/**
	 * Get all event with id
	 *
	 * @return The response from the server, that the selected event
	 */
	put: async function (event: Event): Promise<JSON> {
		const response = await AuthService.put('event/' + event.$id, event);
		const responseData = response.data.data;

		return responseData;
	},

	/**
	 * Get all event with id
	 *
	 * @return The response from the server, that the selected event
	 */
	post: async function (event: Event): Promise<JSON> {
		const response = await AuthService.post('event', event);
		const responseData = response.data.data;

		return responseData;
	},

	delete: async function (event: Event): Promise<JSON> {
		const resp = await AuthService.delete('event/' + event.$id);
		return resp.data.data;
	},
	refreshClients: async function (event: Event): Promise<JSON> {
		const response = await AuthService.post('event/' + event.$id + "/refresh", {
			'reason': "Event refresh"
		})
		return response.data.data;
	},

	getUsers: async function (event: Event | number, page?: number, search?: string): Promise<JSON> {
		let eventId = typeof event === "number" ? event : event.$id;
		let url = `event/${eventId}/users?`;

		if (page) {
			url += `page=${page}`;
		}
		if (search?.length) {
			url += `&search=${search}`;
		}

		let response = await AuthService.get(url);
		return response.data.data;
	},

	validateEmail: async (eventId: number, email: String) => {
		const response = await AuthService.get("event/" + eventId + "/security/validate_mail",
			{
				params: {
					...(email && { email: email })
				},
			}
		).catch(function (error) {

			throw new Error(error.response.data.code);
		})
		return response.data.data
	},
	updateDomains: async (eventId: number, defaultUrl: string, aliasses: string[]) => {
		const resp = await AuthService.post("event/" + eventId + "/domains", {
			"default": defaultUrl,
			"aliasses": aliasses
		});
		return resp.data.data;
	},
	pingDomain: async (domain: string) => {
		const resp = await AuthService.get("/pingDomain", {
			"domain": domain
		});
		return resp.data.data;
	},
	exportEventUsers: async function (eventId: number): Promise<void> {
		const resp = await AuthService.get(`event/${eventId}/export/event_users`, {});
		return resp;
	},
	clearUserData: async function (eventId: number): Promise<void> {
		const resp = await AuthService.delete(`event/${eventId}/clearUserData`);
		return resp;
	},
	checkSlug: async function (slug: String): Promise<void> {
		const resp = await AuthService.get(`event/checkSlug`, { slug: slug });
		return resp;
	},
	duplicateEvent: async function (eventId: number, duplicateEventId: number, options): Promise<void> {
		const resp = await AuthService.post("event/" + eventId + "/duplicate", {
			duplicateEventId: duplicateEventId,
			options: options
		});
		return resp;
	},
	archive: async function (eventId: number) {
		const resp = await AuthService.get(`event/${eventId}/archive`);
		return resp.data.data;
	}
};

export default EventService;
