import React, {FC, useEffect} from 'react';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import InnerHTML from 'dangerously-set-html-content';

const JiraBugCollectorComponent: FC = () => {

    useEffect(() => {
        /*const script = document.createElement("script");

        script.src = "https://headr.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-9zew5j/b/7/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=63ba180f";
        script.async = true; 

        document.body.appendChild(script);*/
    } , []);

    
    const renderScripts = () => {
        return (
            <>
                <InnerHTML html={'<script type="text/javascript" src="https://headr.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-9zew5j/b/7/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=63ba180f"></script>'} />
            </>
        );
    };
    return (
        <>  
            {renderScripts()}
        </>
    );
}


export default JiraBugCollectorComponent;