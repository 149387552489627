import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ClimbingBoxLoader, BeatLoader } from 'react-spinners';

// Layout Blueprints
import {
	LeftSidebar,
	CollapsedSidebar,
} from './layout-blueprints';

import { Alert } from "reactstrap";
import { Provider as EventProvider } from './context/EventContext';
import { Provider as OrganisationProvider } from './context/OrganisationContext'

// Error pages
import PageError403 from './pages/PageError403';
import PageError404 from './pages/PageError404';
import PageError500 from './pages/PageError500';
import PageError505 from './pages/PageError505';
import JiraBugCollectorComponent from './components/JiraBugCollectorComponent';
import ProtectedRoute from './HOC/ProtectedRoute';
import PageGraph from './pages/PageGraph';

const GlobalRoutes = lazy(() => import('./GlobalRoutes'))
const PageLogin = lazy(() => import('./pages/PageLogin/PageLogin'));
const PageElevatedLogin = lazy(() => import('./pages/PageElevatedLogin'));
const PageEvent = lazy(() => import('./pages/Events/PageEvent'));
const PageOrganisation = lazy(() => import('./pages/Organisations/PageOrganisation'));

const Router = () => {
	const pageVariants = {
		initial: {
			opacity: 0,
			scale: 0.99,
		},
		in: {
			opacity: 1,
			scale: 1,
		},
		out: {
			opacity: 0,
			scale: 1.01,
		},
	};

	const pageTransition = {
		type: 'tween',
		ease: 'anticipate',
		duration: 0.4,
	};

	const getEnvironment = () => {
		return process.env.REACT_APP_ENV
	};

	const SuspenseLoading = () => {
		return (
			<>
				<div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
					<div className="d-flex align-items-center flex-column px-4">
						<BeatLoader color={'#48C16F'} loading={true} />
					</div>
				</div>
			</>
		);
	};

	return (
		<>
			<AnimatePresence>
				<Suspense fallback={<SuspenseLoading />}>
					{getEnvironment() == "staging" ?
						<Alert className="mb-0 rounded-0 text-center" color="warning" style={{ height: "3rem" }}>🚨 This is the staging environment 🚨 </Alert>
						: ""}
					{getEnvironment() == "dev" ?
						<Alert className="mb-0 rounded-0 text-center" color="warning" style={{ height: "3rem" }}>🚧 This is the development environment 🚧 </Alert>
						: ""}
					<motion.div
						initial="initial"
						animate="in"
						exit="out"
						variants={pageVariants}
						transition={pageTransition}
					>
						<Routes>
							<Route path='/*' element={
								<ProtectedRoute>
									<LeftSidebar>
										<GlobalRoutes />
									</LeftSidebar>
								</ProtectedRoute>}
							/>

							<Route path='login' element={<PageLogin />} />
							<Route path='elevated' element={<PageElevatedLogin />} />

							<Route path='event/:id/*' element={
								<ProtectedRoute>
									<EventProvider>
										<CollapsedSidebar>
											<PageEvent />
										</CollapsedSidebar>
									</EventProvider>
								</ProtectedRoute>}
							/>

							<Route path='organisation/:id/*' element={
								<ProtectedRoute>
									<OrganisationProvider>
										<CollapsedSidebar>
											<PageOrganisation />
										</CollapsedSidebar>
									</OrganisationProvider>
								</ProtectedRoute>}
							/>

							<Route path="graph" element={<PageGraph />} />

							<Route path="PageError403" element={PageError403} />
							<Route path="PageError404" element={PageError404} />
							<Route path="PageError500" element={PageError500} />
							<Route path="PageError505" element={PageError505} />
						</Routes>
					</motion.div>
					{
						getEnvironment() == "staging"
							? <JiraBugCollectorComponent />
							: ""
					}
				</Suspense >
			</AnimatePresence >
		</>
	);
};

export default Router;
