export default class Option {
	private id: number | null;
	private value: string | null;
	private label: string | null;
	private language: string | null;
	private color: string;

	constructor(
		id: number | null = null,
		value: string | null = null,
		label: string | null = null,
		language: string | null = null,
		color: string = '',
	) {
		this.id = id;
		this.value = value;
		this.label = label;
		this.language = language;
		this.color = color;
	}

	public static fromJSON(json: any): Option {
		let option: Option = new Option();
		Object.assign(option, json);

		//check if poll has content otherwise use default content
		if (json.content.length > 0) {
			option.$language = json.content[0].language;
			option.$label = json.content[0].label;
		}
		else if (json.default_content.length > 0) {
			option.$label = json.default_content[0].label;
			option.$language = json.default_content[0].language;
		}
		else {
			option.$label = "No label";
			option.$language = "en";
		}
		return option;
	}

	public get $id(): number | null {
		return this.id;
	}

	public get $value(): string | null {
		return this.value;
	}

	public get $label(): string | null {
		return this.label;
	}

	public get $language(): string | null {
		return this.language;
	}

	public get $color(): string {
		return this.color;
	}

	public set $language(value: string | null) {
		this.language = value;
	}

	public set $id(value: number | null) {
		this.id = value;
	}

	public set $value(value: string | null) {
		this.value = value;
	}

	public set $label(value: string | null) {
		this.label = value;
	}

	public set $color(value: string) {
		this.color = value;
	}

	public validate(): { [key: string]: string } | null {
		const errors: { [key: string]: string } = {};

		// Check if the required fields are not null
		if (this.value === null) {
			errors.title = "Value is required";
		}

		if (this.label === null) {
			errors.label = "Label is required";
		}

		// If there are errors, return the error object; otherwise, return null
		return Object.keys(errors).length > 0 ? errors : null;
	}
}