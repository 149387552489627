import React, { FC } from 'react';
import Chart from 'react-apexcharts';
import ColorUtils from '../../../../../../utils/ColorUtils';

interface BarChartProps {
    data: Array<any>;
    options: Array<String>;
    color?: string;
	colors: string[];
}

const BarChart: FC<BarChartProps> = (props: BarChartProps) => {
    const options = {
        //colors: props.colors ? props.colors : [ColorUtils.webbleUpGreen],
        dataLabels: {
            enabled: false
        },
        plotOptions: {
            bar: {
                horizontal: true
            }
        },
        xaxis: {
            categories: props.options
        }
    }

    const series = [
        {
            name: "series-1",
            data: props.data === undefined ? [] : props.data
        }
    ]

    console.log(options);
    console.log(series);

    return (
        <>
            <Chart options={options} series={series} type="bar" />
        </>
    );
}
export default BarChart;