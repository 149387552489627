import React, { FC, useState, useEffect } from 'react';
import BarChart from '../Events/Advanced/ServicesPages/PageServicesPoll/Charts/BarChart';
import PieChart from '../Events/Advanced/ServicesPages/PageServicesPoll/Charts/PieChart';
import TextTable from '../Events/Advanced/ServicesPages/PageServicesPoll/Charts/TextTable';
import { useSearchParams } from 'react-router-dom';
import QuestionService from '../../Services/QuestionService';
import { withSocket } from '../../HOC/Socket/withSocket';
import ColorUtils from '../../utils/ColorUtils';

interface Props {

}

const PageGraph: FC<Props> = (props) => {
	let [searchParams, setSearchParams] = useSearchParams();

	const [results, setResults] = useState<any[]>([]);
	const [options, setOptions] = useState<any[]>([]);
	const [colors, setColors] = useState<string[]>([]);

	const type = searchParams.get('type');
	const eventId = searchParams.get('event');
	const pollId = searchParams.get('poll');
	const questionId = searchParams.get('question');

	const initData = async () => {
		const response = await QuestionService.getAnswersGraph(eventId, pollId, questionId);
		
		const values = Object.values(response).map((option: any) => option.value)
		const colors = Object.values(response).map((option: any) => option.color ? option.color : ColorUtils.webbleUpGreen)

		setResults(values);
		setOptions(Object.keys(response));
		setColors(colors);
	}

	const getResults = async () => {
		const response = await QuestionService.getAnswersGraph(eventId, pollId, questionId);
		const values = Object.values(response).map((option: any) => option.value)

		setResults(values);
	}

	const subscribeToResults = () => {
		props.subscribe(`notification/${eventId}/headr/pollService/${pollId}/question/${questionId}`, (msg) => {
			console.log(msg);
		}, true)
	}

	useEffect(() => {
		initData();

		const interval = setInterval(async () => {
			getResults();
		}, 5 * 1000);

		// subscribeToResults()

		return () => clearInterval(interval);
	}, [])

	function graph() {
		if (type == "BAR") {
			return <BarChart data={results} options={options} colors={colors}/>
		}
		else if (type == "PIE") {
			return <PieChart data={results} options={options} colors={colors} height='100%' width='100%'/>
		}
		else {
			return <TextTable data={results} options={options} />
		}
	}

	return (
		<div style={{width: '100vh', height: '100vh', margin: 'auto'}}>
			{graph()}
		</div>
	)
}
export default withSocket(PageGraph);