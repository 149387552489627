import React, { FC } from 'react';
import Chart from 'react-apexcharts';
import { colors } from 'react-select/dist/declarations/src/theme';
import ColorUtils from '../../../../../../utils/ColorUtils';

interface PieChartProps {
	data: Array<any>;
	options: Array<String>;
	color?: string;
	width?: string;
	height?: string;
	colors?: string[];
}

const PieChart: FC<PieChartProps> = (props: PieChartProps) => {



	const options = {
		//colors: props.colors ? props.colors : [ColorUtils.webbleUpGreen],
		chart: {
			type: 'pie',
			toolbar: {
				show: true,
			},
		},
		labels: props.options,
		dataLabels: {
			enabled: true,

			formatter: function (val, opt) {
				//round to 2 decimal places
				val = Math.round(val * 100) / 100;
				return val + "%";
			},
			offsetX: 0,
			dropShadow: {
				enabled: true
			}
		},
	}

	const series = [{
		data: props.data
	}]

	
	console.log(options);
    console.log(props.data);

	return (
		<Chart
			options={options}
			series={props.data}
			type="pie"
			width={props.width}
			height={props.height}
		/>
	);
}
export default PieChart;