
import exp from 'constants';
import React, { FC, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import {
	Row,
	Col,
	Button,
	Collapse,
	Label,
	Input,
	Table,
} from 'reactstrap';

interface TextTableProps {
    data: Array<any>;
    options: Array<String>;
    color?: string;
}



const TextTable: FC<TextTableProps> = (props: TextTableProps) => {
    const [total, setTotal] = useState<number>(0);
   
    useEffect(() => {
        let total = 0;
        props.data.forEach((element) => {
            total += element;
        });
        setTotal(total);
    }, [props.data]);
 

    return (
        <>
           <Table>
           <thead className="text-capitalize font-weight-bold">
					<tr>
						<th className="text-uppercase bg-light text-center">
							Answers
						</th>
                        <th className="text-uppercase bg-light text-center">
							Amount of votes ({total})
						</th>
                        <th className="text-uppercase bg-light text-center">
                            Percentage
						</th>
					</tr>
				</thead>
				<tbody>
					{props.options.map((option, index) => {
                      return ( 
                        <tr key={'tr-option-' + index}>
                            <td className="text-center">
                                {option}
                            </td>
                            <td className="text-center">
                                {props.data[index]}
                            </td>
                            <td className="text-center">
                                {(props.data[index]/total*100).toFixed(2)}%
                            </td>
                        </tr>
                      )  
                    }
                    )}
                </tbody>

           </Table>
        </>
    );
}
export default TextTable;